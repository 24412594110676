// src/app/MFAPanel.jsx

import React from 'react';

// materialUI imports
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: "url(" + theme.backgroundImage + ")"
    },
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    width: '75%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  instructions: {
    marginTop: theme.spacing(2)
  }
}));

const codeLabels = {
  "SMS_MFA": 'SMS code',
  "SOFTWARE_TOKEN_MFA": "Email code"
};

export default function MFAPanel(props) {
  const classes = useStyles();

  function handleSubmit(event) {
    if (event) event.preventDefault();
    props.handleMFA(props.authCode, props.mfaType);
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline/>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
          <Typography variant="h5">
            Identity authentication
          </Typography>
          <Typography variant="body2" className={classes.instructions}>
            A text message has been sent to your mobile device. Please enter your code below:
          </Typography>
          <TextField
            required
            autoFocus
            label={codeLabels[props.mfaType]}
            name="authCode"
            value={props.authCode}
            variant="filled"
            onChange={props.handleChange}
            className={classes.input}
          />
          {(props.showErrMsg) &&
            <Typography variant="body2" color="error">
              {props.errorMsg}
            </Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Submit
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

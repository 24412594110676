// config.js

const dev = {
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_5IOIu3Tta',
    APP_CLIENT_ID: '7o3a61tle8a352h8poh0ldcsce',
    IDENTITY_POOL_ID: 'us-east-1:365cc278-e670-4460-ab65-7f519bca3883',
    MANDATORY_SIGN_IN: true,
  },
  routing: {
    SUCCESS_ROUTE: "https://dev.accretivelabs.com/app/index.html",
    SIGNOUT_ROUTE: "https://www.accretivelabs.com",
    ABORT_ROUTE: "https://www.accretivelabs.com",
    RETRY_INTERVAL: 1000,
  },
  cookies: {
    maxAge: 900,
    path: "/",
    samesite: "strict",
    secure: true
  }
}

const prod = {
  cognito: {
    REGION: '##PROD_REGION##',
    USER_POOL_ID: '##PROD_USER_POOL_ID##',
    APP_CLIENT_ID: '##PROD_APP_CLIENT_ID##',
    IDENTITY_POOL_ID: '##PROD_IDENTITY_POOL_ID##',
    MANDATORY_SIGN_IN: true,
  },
  routing: {
    SUCCESS_ROUTE: "##SUCCESS_ROUTE##",
    SIGNOUT_ROUTE: "##SIGNOUT_ROUTE##",
    ABORT_ROUTE: "##ABORT_ROUTE##",
    RETRY_INTERVAL: 500,
  },
  cookies: {
    maxAge: 900,
    path: "/",
    samesite: "strict",
    secure: true
  }
}

const config = process.env.APP_STAGE === "prod" ? prod : dev;

export default {
  ...config
};

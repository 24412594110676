// src/app/cookie.js

import config from '../config.js';
import Cookies from 'js-cookie';

const cookieConfig = config.cookies;
const attrs = {};
if (cookieConfig.path) { attrs.path = cookieConfig.path; }
if (cookieConfig.maxAge) { attrs.expires = cookieConfig.maxAge/24.0/60.0/60.0; }
if (cookieConfig.samesite) { attrs.samesite = cookieConfig.samesite; }
if (cookieConfig.secure) { attrs.secure = cookieConfig.secure; }


export const setCookie = (key, value) => {
  Cookies.set(key, value, attrs);
};

export const getCookie = (key) => {
  return(Cookies.get(key, attrs));
};

export const clearCookie = (key) => {
  Cookies.remove(key, attrs);
};

export const clearAllCookies = () => {
  Object.keys(Cookies.get()).forEach(function(cookieName) {
    Cookies.remove(cookieName, attrs);
  });
};

// src/styles/theme.jsx

import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#cfcfcf',
      main: '#9e9e9e',
      dark: '#707070',
    },
    primary: {
      light: '#4b5266',
      main: '#222a3c',
      dark: '#000017',
    },
    error: {
      main: '#b71c1c',
    }
  },
  backgroundImage: "https://www.accretivelabs.com/wp-content/uploads/2019/08/graphy.png"
});

export default theme;

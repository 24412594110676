// src/app/TimedRedirect.jsx

import React from 'react';

class PeriodicTask extends React.Component {

  componentDidMount() {
    if (this.props.interval > 0) {
      this.interval = setInterval(() => this.props.task(), this.props.interval);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.interval !== this.props.interval) {
      clearInterval(this.interval);
      if (this.props.interval > 0) {
        setInterval(() => this.props.task(), this.props.interval);
      }
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval)
  };

  render() {
    return false;
  }

};

PeriodicTask.defaultProps = {
  task: () => {},
  interval: -1
}

export default PeriodicTask;

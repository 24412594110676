// src/app/GreetingPanel.jsx

import React from 'react';

// materialUI imports
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: "url(" + theme.backgroundImage + ")"
    },
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  centerButton: {
    height: 72,
    width: '75%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  cornerButton: {
    margin: theme.spacing(1),
  },
  input: {
    width: '100%',
    margin: theme.spacing(2),
  },
}));

export default function GreetingPanel(props) {
  const classes = useStyles();

  function handleSignOut() { props.handleSignOut(); }
  function handleSwitchUser() { props.handleSwitchUser(); }
  function handleContinue() { props.handleContinue(); }

  return (
    <Container maxWidth="xs">
      <CssBaseline/>
      <Paper className={classes.paper}>
        <Typography variant="h5">
          Continue as logged-in user:
        </Typography>
        <Button
          size="large"
          className={classes.centerButton}
          onClick={handleContinue}
        >
          {props.username}
        </Button>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              color="secondary"
              className={classes.cornerButton}
              onClick={handleSignOut}
            >
              Sign out
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              className={classes.cornerButton}
              onClick={handleSwitchUser}
            >
              Switch user
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

// src/app/LoadingPanel.jsx

import React from 'react';

// materialUI imports
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: "url(" + theme.backgroundImage + ")"
    },
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    margin: theme.spacing(2),
  },
}));

export default function LoadingPanel(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <CssBaseline/>
      <Paper className={classes.paper}>
        <CircularProgress
          size={80}
          className={classes.progress}
          color="primary"/>
        {(props.caption) &&
          <Typography
            variant="caption"
            color="secondary"
          >{props.caption}
          </Typography>}
      </Paper>
    </Container>
  );
};

// src/app/auth.js

import config from '../config.js';
import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    mandatorySignIn: config.cognito.MANDATORY_SIGN_IN,
  }
});
Auth.configure();

export default Auth;

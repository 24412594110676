// src/app/NewAccountPanel.jsx

import React from 'react';

// materialUI imports
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: "url(" + theme.backgroundImage + ")"
    },
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
  },
  containerCol: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    width: '100%',
    margin: theme.spacing(2),
  },
  newPw: {
    width: '100%',
    margin: theme.spacing(2),
  },
  oldPw: {
    width: '100%',
    margin: theme.spacing(2),
    marginBottom: theme.spacing(4),
  }
}));

function toTitleCase(string) {
  var newStr = string.toLowerCase().split(" ");
  for(var i = 0; i< newStr.length; i++){
     newStr[i] = newStr[i][0].toUpperCase() + newStr[i].slice(1);
  }
  return newStr;
}

export default function NewAccountPanel(props) {
  const classes = useStyles();

  function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!chkPws()) { return; }
    props.handleNewAcct(props.formValues);
  }

  function chkPws() {
    // Check that new passwords match
    return(props.formValues.newPassword === props.formValues.repeatNewPassword)
  }

  const passwordPanel = (
    <Container maxWidth="xs" className={classes.containerCol}>
      <TextField
        required
        autoFocus
        label="Old Password"
        name="oldPassword"
        type="password"
        value={props.formValues.oldPassword}
        variant="filled"
        onChange={props.handleChange}
        className={classes.oldPw}
      />
      <TextField
        required
        error={!(chkPws())}
        label="New Password"
        name="newPassword"
        type="password"
        value={props.formValues.newPassword}
        variant="filled"
        onChange={props.handleChange}
        className={classes.newPw}
      />
      <TextField
        required
        error={!(chkPws())}
        label="Repeat New Password"
        name="repeatNewPassword"
        type="password"
        value={props.formValues.repeatNewPassword}
        variant="filled"
        onChange={props.handleChange}
        className={classes.newPw}
      />
      {(props.showErrMsg) &&
        <Typography variant="body2" color="error">
          {props.errorMsg}
        </Typography>}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={!(chkPws())}
      >
        Change Password
      </Button>
    </Container>
  );
  const newAttrsPanel = (
    <Container maxWidth="xs" className={classes.containerCol}>
    {props.needAttrs.map((attr, i) => {
      var attrName = "attr_" + String(attr);
      return (
        <TextField
          label={toTitleCase(attr)}
          value={props.formValues[attrName]}
          name={attrName}
          variant="filled"
          onChange={props.handleChange}
          className={classes.input}
          key={i}
        />
      );}
    )}
    </Container>
  );

  return (
    <Container maxWidth="md">
      <CssBaseline/>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
          <Typography variant="h5" align="left">
            Please provide the required information and set a new password:
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              {newAttrsPanel}
            </Grid>
            <Grid item xs={6}>
              {passwordPanel}
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};

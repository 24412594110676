// src/app/LoginPanel.jsx

import React from 'react';

// materialUI imports
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary.main,
      backgroundImage: "url(" + theme.backgroundImage + ")"
    },
  },
  paper: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    width: '100%',
    margin: theme.spacing(2),
  },
}));

export default function LoginPanel(props) {
  const classes = useStyles();

  function handleSubmit(event) {
    if (event) event.preventDefault();
    props.handleLogIn(props.username, props.password);
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline/>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.paper}>
          <TextField
            required
            label="Email Address"
            name="username"
            value={props.username}
            variant="filled"
            onChange={props.handleChange}
            className={classes.input}
          />
          <TextField
            required
            label="Password"
            name="password"
            type="password"
            value={props.password}
            variant="filled"
            onChange={props.handleChange}
            className={classes.input}
          />
          {(props.showErrMsg) &&
            <Typography variant="body2" color="error">
              {props.errorMsg}
            </Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Log-in
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

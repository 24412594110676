// src/app/MasterPanel.jsx

import React from 'react';

// Custom auth components
import GreetingPanel from './GreetingPanel.jsx';
import LoadingPanel from './LoadingPanel.jsx';
import LoginPanel from './LoginPanel.jsx';
import MFAPanel from './MFAPanel.jsx';
import NewAccountPanel from './NewAccountPanel.jsx';
import NewPasswordPanel from './NewPasswordPanel.jsx';
import PeriodicTask from './PeriodicTask.jsx';

// Other modules
import Auth from './auth.js';
import { setCookie, clearAllCookies } from './cookie.js';
import config from '../config.js';

// Redirect paths
const successRoute = config.routing.SUCCESS_ROUTE;
const signoutRoute = config.routing.SIGNOUT_ROUTE;
const abortRoute = config.routing.ABORT_ROUTE;
const retryInterval = config.routing.RETRY_INTERVAL;

class MasterPanel extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      formValues: {
        username: '',
        password: '',
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        authCode: '',
      },
      panelState: "INITIAL_LOAD",
      errMsg: '',
      showErrMsg: false,
      needAttrs: [],
      userObj: null,
      authUser: null,
      needAuthUser: true,
      continueLogin: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleNewAcct = this.handleNewAcct.bind(this);
    this.handleNewPwOnly = this.handleNewPwOnly.bind(this);
    this.handleMFA = this.handleMFA.bind(this);
    this.handleContinueAsUser = this.handleContinueAsUser.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleSwitchUser = this.handleSwitchUser.bind(this);
  }

  // Authentication functions
  async getCurrentAuthUser() {
    // Put the current authenticated user into userObj
    if (this.state.needAuthUser) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.setState((state) => {return {...state, authUser: user, needAuthUser: false}});
      } catch(err) {
        this.setState((state) => {return {...state, authUser: null, needAuthUser: false}});
      }
      try {
        const sess = await Auth.currentSession();
        setCookie("idToken", sess.idToken.jwtToken);
        setCookie("accessToken", sess.accessToken.jwtToken);
      } catch(err) {
        console.error("Unable to retrieve user session.");
      }
      this.getNextPanelState();
    }
  }
  async setCookieFromSession() {
    // Store session tokens in cookie, e.g., for browser access to restricted content
    if (this.state.authUser) {
      try {
        const sess = await Auth.currentSession();
        setCookie("idToken", sess.idToken.jwtToken);
        setCookie("accessToken", sess.accessToken.jwtToken);
      } catch(err) {
        console.error("Unable to retrieve user session.");
      }
    }
  };

  // Process state changes
  resetState = (panelState="INITIAL_LOAD") => {
    if (this.props.debug) { console.log("Resetting state."); }
    this.setState({
      formValues: {
        username: '',
        password: '',
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
        authCode: '',
      },
      panelState: panelState,
      errMsg: '',
      showErrMsg: false,
      needAttrs: [],
      userObj: null,
      authUser: null,
      needAuthUser: true,
      continueLogin: false,
    });
  };
  handleChange = (event) => {
    event.persist();
    this.setState(Object.assign({}, this.state, { formValues:
      Object.assign({}, this.state.formValues,
        { [event.target.name]: event.target.value })
      })
    );
  };
  _updateFormValues = (newVals) => {
    this.setState((state) => {
      return Object.assign({}, state, { formValues:
        Object.assign({}, state.formValues, {...newVals})} );
    });
  };
  handleChallenge = (cognitoUser) => {
    switch(cognitoUser.challengeName) {
      case "SMS_MFA":
        // User needs to submit SMS MFA code
        return("SMS_MFA");
      case "SOFTWARE_TOKEN_MFA":
        // User needs to submit one-time-password
        console.error("Challenge type 'SOFTWARE_TOKEN_MFA' not supported.");
        return;
      case "NEW_PASSWORD_REQUIRED":
        // User needs to input a new password and any required attributes
        if (cognitoUser.challengeParam
          && cognitoUser.challengeParam.requiredAttributes
          && cognitoUser.challengeParam.requiredAttributes.length > 0) {
            this.setState((state) => ({...state, needAttrs: cognitoUser.challengeParam.requiredAttributes}));
            cognitoUser.challengeParam.requiredAttributes.map((attr, i) => {
              var attrName = "attr_" + String(attr);
              var tmp = {}
              tmp[attrName] = ''
              this._updateFormValues(tmp);
              return true;
            });
            return("SETUP_NEW_ACCOUNT");
        } else {
          return("ONLY_NEW_PASSWORD_REQUIRED");
        }
      case "MFA_SETUP":
        return("SETUP_MFA");
      default:
       return("NOT_LOGGED_IN");
    }
  };
  getNextPanelState = () => {

    var nextState;
    const authUser = this.state.authUser;
    const cognitoUser = this.state.userObj;
    const panelState = this.state.panelState;
    const continueLogin = this.state.continueLogin;
    if (this.props.debug) { console.log("Getting next panel state..."); }

    // Handle current authenticated user
    if (authUser) {
      if (!continueLogin && (panelState === "INITIAL_LOAD" || panelState === "GREETING")) {
        nextState = "GREETING";
      } else {
        nextState = "LOGGED_IN";
      }
    } else if (panelState === "SIGNED_OUT") {
      nextState = panelState;
    } else if (cognitoUser && cognitoUser.challengeName) {
      nextState = this.handleChallenge(cognitoUser);
    } else {
      nextState = "NOT_LOGGED_IN";
    }

    if (nextState && (nextState !== panelState)) {
      if (this.props.debug) { console.log(panelState, "to", nextState); }
      this.setState((state) => ({...state, panelState:nextState, }));
    }
    if (this.props.debug) { console.log("Next state obtained."); }
  }

  // State selectors
  getAuthUserEmail = () => {
    return (this.state.authUser) ? this.state.authUser.attributes.email : ""
  };

  // Event handlers for authentication actions; passed to children
  async handleLogIn(username, password) {
    if (this.props.debug) { console.log("Handling log-in..."); }
    if (this.props.debug) { console.log(username, password); }
    try {
      const user = await Auth.signIn(username, password);
      this.setState((state) => {
        return {
          ...state,
          needAuthUser: true,
          userObj: user,
          showErrMsg: false,
          errMsg: ''
        }
      });
      // this.getNextPanelState(user);
    } catch(err) {
      if (this.props.debug) { console.log(err); }
      if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        // Username + Password not valid
        const tmpFormValues = Object.assign(this.state.formValues, {
          username: '', password: ''
        });
        this.setState((state) => ({
          ...state,
          errMsg: "Invalid username or password.",
          showErrMsg: true,
          formValues: tmpFormValues
        }));
      } else if (err.code === 'UserNotConfirmedException') {
      } else {
        console.log(err)
      }
    };
    if (this.props.debug) { console.log("Login handled."); }
  };
  async handleNewPwOnly(username, oldPw, newPw, repNewPw) {
    if (this.props.debug) { console.log("Handling new password..."); }
    const formValues = this.state.formValues;

    // Confirm that new passwords match
    if (formValues.newPassword !== formValues.repeatNewPassword) {
      this.setState((state) => ({
        ...state,
        errMsg: "New passwords must match.",
        showErrMsg: true,
        formValues: Object.assign({}, state.formValues, {
          oldPassword: '', newPassword: '', repeatNewPassword:''
        })
      }));
      return;
    }

    // Confirm that old password is correct
    var errMsg
    try {
      var user = await Auth.signIn(formValues.username, formValues.oldPassword);
    } catch(err) {
      if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        // Username or password not valid
        errMsg = "Incorrect old password.";
      } else {
        errMsg = err.message;
      }
      this.setState((state) => ({
        ...state,
        errMsg: errMsg,
        showErrMsg: true,
        formValues: Object.assign({}, state.formValues, {
          oldPassword: '', newPassword: '', repeatNewPassword:''
        })
      }));
      return;
    }

    // Respond to new-password challenge
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      try {
        var cognitoUser = await Auth.completeNewPassword(user, newPw);
        this.setState((state) => ({
          ...state,
          errMsg: '',
          showErrMsg: false,
          userObj: cognitoUser,
          needAuthUser: true
        }));
      } catch(err) {

        console.log(err);
        this.setState((state) => ({
          ...state,
          errMsg: err.message,
          showErrMsg: true,
          formValues: Object.assign({}, state.formValues, {
            oldPassword: '', newPassword: '', repeatNewPassword:''
          })
        }));
        return;
      }
    }
  }
  async handleNewAcct() {
    if (this.props.debug) { console.log("Handling new account..."); }
    const formValues = this.state.formValues;

    // Confirm that new passwords match
    if (formValues.newPassword !== formValues.repeatNewPassword) {
      this.setState((state) => ({
        ...state,
        errMsg: "New passwords must match.",
        showErrMsg: true,
        formValues: Object.assign({}, state.formValues, {
          oldPassword: '', newPassword: '', repeatNewPassword:''
        })
      }));
      return;
    }

    // Confirm that old password is correct
    var errMsg;
    try {
      var user = await Auth.signIn(formValues.username, formValues.oldPassword);
    } catch(err) {
      if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
        // Username or password not valid
        errMsg = "Incorrect old password.";
      } else {
        errMsg = err.message;
      }
      this.setState((state) => ({
        ...state,
        errMsg: errMsg,
        showErrMsg: true,
        formValues: Object.assign({}, state.formValues, {
          oldPassword: '', newPassword: '', repeatNewPassword:''
        })
      }));
      return;
    }

    // Respond to new-password challenge
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const { requiredAttributes } = user.challengeParam;
      var newAttrs = requiredAttributes.reduce((acc, cv) => {
          var attrName = "attr_" + String(cv);
          acc[cv] = formValues[attrName];
          return(acc);
        }, {});

      try {
        var cognitoUser = await Auth.completeNewPassword(
          user, formValues.newPassword, newAttrs);
        this.setState((state) => ({
          ...state,
          errMsg: '',
          showErrMsg: false,
          userObj: cognitoUser,
          needAuthUser: true
        }));
      } catch(err) {

        console.log(err);
        this.setState((state) => ({
          ...state,
          errMsg: err.message,
          showErrMsg: true,
          formValues: Object.assign({}, state.formValues, {
            oldPassword: '', newPassword: '', repeatNewPassword:''
          })
        }));
        return;
      }
    }
  }
  async handleMFA(code, mfaType) {
    const userObj = this.state.userObj;
    if (this.props.debug) { console.log("Handling MFA..."); }
    if (userObj.challengeName === "SMS_MFA"
        || userObj.challengeName === "SOFTWARE_TOKEN_MFA") {
      try {
        const user = await Auth.confirmSignIn(userObj, code, mfaType);
        if (this.props.debug) { console.log("MFA success."); }
        this.setState({
          userObj: user,
          needAuthUser: true
        });
        // this.getNextPanelState(user);
      } catch(err) {
        console.log(err);
        this.setState({
          errMsg: "Invalid code. Please try again.",
          showErrMsg: true,
        })
      }
    }
    if (this.props.debug) { console.log("MFA handled."); }
    return;
  }
  async handleContinueAsUser() {
    if (this.props.debug) { console.log("Continuing as current user..."); }
    this.setState({ continueLogin:true, needAuthUser: true });
    if (this.props.debug) { console.log("ContinueLogin set."); }
  }
  async handleSignOut() {
    if (this.props.debug) { console.log("Signing out..."); }
    try {
      await Auth.signOut();
      clearAllCookies();
      this.resetState("SIGNED_OUT");
    } catch(err) {
      console.log(err);
    }
    if (this.props.debug) { console.log("Signed out."); }
  }
  async handleSwitchUser() {
    if (this.props.debug) { console.log("Switching user..."); }
    try {
      await Auth.signOut();
      clearAllCookies();
      this.resetState("NOT_LOGGED_IN");
    } catch(err) {
      console.log(err);
    }
    if (this.props.debug) { console.log("Signed out. Ready to switch."); }
  }

  // React functions
  componentDidMount() {
    this.getCurrentAuthUser();
    // this.setCookieFromSession();
  }
  componentDidUpdate() {
    this.getCurrentAuthUser();
    // this.setCookieFromSession();
  }
  render() {
    const {panelState, formValues, needAttrs, errMsg, showErrMsg} = this.state;

    // Render panel based on current state
    switch(panelState) {
      case "INITIAL_LOAD":
        // Loading page
        return(
          <LoadingPanel caption="Loading..."/>
        );
      case "GREETING":
        return(
          <GreetingPanel
            username={this.getAuthUserEmail()}
            handleContinue={this.handleContinueAsUser}
            handleSignOut={this.handleSignOut}
            handleSwitchUser={this.handleSwitchUser}
          />
        );
      case "NOT_LOGGED_IN":
        return(
          <LoginPanel
            username={formValues.username}
            password={formValues.password}
            showErrMsg={showErrMsg}
            errorMsg={errMsg}
            handleChange={this.handleChange}
            handleLogIn={this.handleLogIn}
          />
        );
      case "ONLY_NEW_PASSWORD_REQUIRED":
        return(
          <NewPasswordPanel
            username={formValues.username}
            oldPassword={formValues.oldPassword}
            newPassword={formValues.newPassword}
            repeatNewPassword={formValues.repeatNewPassword}
            showErrMsg={showErrMsg}
            errorMsg={errMsg}
            handleChange={this.handleChange}
            handleChgPw={this.handleNewPwOnly}/>
        );
      case "SETUP_NEW_ACCOUNT":
        return(
          <NewAccountPanel
            formValues={formValues}
            needAttrs={needAttrs}
            showErrMsg={showErrMsg}
            errorMsg={errMsg}
            handleChange={this.handleChange}
            handleNewAcct={this.handleNewAcct}/>
        );
      case "SMS_MFA":
        return(
          <MFAPanel
            authCode={formValues.authCode}
            mfaType={"SMS_MFA"}
            showErrMsg={showErrMsg}
            errorMsg={errMsg}
            handleChange={this.handleChange}
            handleMFA={this.handleMFA}
          />
        )
      case "SOFTWARE_TOKEN_MFA":
        return(
          <MFAPanel
            authCode={formValues.authCode}
            mfaType={"SOFTWARE_TOKEN_MFA"}
            showErrMsg={showErrMsg}
            errorMsg={errMsg}
            handleChange={this.handleChange}
            handleMFA={this.handleMFA}
          />
        )
      case "LOGGED_IN":
        // Redirect to success route with loading indicator
        return(
          <div>
            <LoadingPanel caption="Authenticating..."/>
            <PeriodicTask
              task={window.location.assign(successRoute)}
              interval={retryInterval}
            />
          </div>
        );
      case "SIGNED_OUT":
        // Redirect to signout route
        return(
          <div>
            <LoadingPanel caption="Logging out..."/>
            <PeriodicTask
              task={window.location.assign(signoutRoute)}
              interval={retryInterval}
            />
          </div>
        );
      default:
        // Redirect to abort route if unexpected state
        return(
          <div>
            <PeriodicTask
              task={window.location.assign(abortRoute)}
              interval={retryInterval}
            />
          </div>
        );
    }
  }
}

MasterPanel.defaultProps = {
  debug: false,
};

export default MasterPanel;
